import React from 'react'

function BoxListItem({imagem,texto}) {
  return (
    <div className="box_hero_left_list_item">
        <div className="box_hero_left_list_item_icon">
            <img src={imagem} alt="imagem_icon" />
        </div>
        <div className="box_hero_left_list_item_text">
            <p>{texto}</p>
        </div>
    </div>
  )
}

export default BoxListItem