import React from "react";

function IconBox({image,text,mail,tel}) {
  return (
    <div className="icon_box">
      <div className="icon_box_icon">
        <img src={image} alt="pin_mapa" />
      </div>
      <div className="icon_box_text">

        {
            text ? <p>{text}</p> : null 
        }

        {
            mail ? <p> <a href={"mailto:" + mail}>{mail}</a> </p> : null
        }

        {
            tel ? <p> <a href={"tel:" + tel}>{tel}</a> </p> : null
        }
        
      </div>
    </div>
  );
}

export default IconBox;
