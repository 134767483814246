import logo from "./img/logos/logo.svg";
import pin from "./img/logos/pin.svg";
import tel from "./img/logos/tel.svg";
import mail from "./img/logos/mail.svg";
import man from "./img/logos/man.png";
import checkmark from "./img/logos/checkmark.svg";
import maskgroup from "./img/logos/maskgroup.svg";
import IconBox from "./Component/IconBox";
import BoxListItem from "./Component/BoxListItem";

function App() {
  return (
    <>
      <div className="hero_section">
        <div className="hero_section_container">
          {/* Helpers */}
          <div className="hero_section_gradiente"></div>
          <div className="hero_section_bg"></div>

          <div className="conteudo">
            <div className="conteudo_container">
              <header>
                <div className="logotipo">
                  <img src={logo} alt="Logo" />
                </div>

                <div className="menu">
                  <IconBox image={pin} text="Braga" />
                  <IconBox image={mail} mail="geral@jp-pichelaria.pt" />
                  <IconBox image={tel} tel="+351 939 568 459" />
                </div>
              </header>

              <div className="box_hero">
                <div className="box_hero_container">
                  <img src={maskgroup} alt="mask" id="maskgroup" />
                  <div className="box_hero_left">
                    <h1>Todo o tipo de serviços de pichelaria</h1>

                    <div className="box_hero_left_list">
                      <BoxListItem imagem={checkmark} texto="Redes de água pluviais e residuais" />
                      <BoxListItem imagem={checkmark} texto="Rede de água potável" />
                      <BoxListItem imagem={checkmark} texto="Rede de rega" />
                    </div>

                    <div className="box_hero_button">
                      <a href="tel:+351 939 568 459">Contactar</a>
                    </div>
                  </div>
                  <div className="box_hero_right">
                    <img src={man} alt="homem" />
                  </div>
                </div>
              </div>
              
            </div>

            {/* Div apenas para o mobile */}
            <div className="mobile_contactos">
              <div className="menu">
                <IconBox image={pin} text="Braga" />
                <IconBox image={mail} mail="geral@jp-pichelaria.pt" />
                <IconBox image={tel} tel="+351 939 568 459" />
              </div>
            </div>

            

          </div>
        </div>
      </div>
    </>
  );
}

export default App;
